import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ToastProvider } from 'react-native-toast-notifications';
import { ReactNode } from 'react';
import AppToast from './AppToast';

interface AppToastProviderProps {
  children: ReactNode;
}

export default function AppToastProvider({ children }: AppToastProviderProps) {
  const edgeInsets = useSafeAreaInsets();
  return (
    <ToastProvider
      offsetTop={edgeInsets.top}
      placement="top"
      renderToast={(toastProps) => <AppToast {...toastProps} />}
    >
      {children}
    </ToastProvider>
  );
}
