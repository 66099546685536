import { activateKeepAwakeAsync, deactivateKeepAwake } from 'expo-keep-awake';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { StorageKeys, storage } from '../utils/storage';

interface KeepAwakeContext {
  screenDisplayOn: boolean;
  setScreenDisplayOn: (value: boolean) => void;
}

const KeepAwakeContext = createContext<KeepAwakeContext | undefined>(undefined);

export const useKeepAwakeContext = (): KeepAwakeContext => {
  const context = useContext(KeepAwakeContext);
  if (!context) {
    throw new Error('useKeepAwake must be used within a KeepAwakeProvider');
  }
  return context;
};

export const KeepAwakeProvider = ({ children }: any) => {
  const [screenDisplayOn, setScreenDisplayOn] = useState(!!storage.getBoolean(StorageKeys.SCREEN_ALWAYS_ON));

  useEffect(() => {
    if (screenDisplayOn) {
      activateKeepAwakeAsync();
    } else {
      deactivateKeepAwake();
    }
  }, [screenDisplayOn]);

  return (
    <KeepAwakeContext.Provider value={{ screenDisplayOn, setScreenDisplayOn }}>{children}</KeepAwakeContext.Provider>
  );
};
