import { TextStyle } from 'react-native';
import { DefaultTheme } from '@react-navigation/native';

// Element props
export const colors = {
  border: '#ccc',
  primary: {
    regular: '#4E3429',
    light: '#4E3429',
    dark: '#3D2920',
  },
  secondary: {
    regular: '#E4CCB0',
    light: '#FFE4C2',
    dark: '#D2B899',
  },
  gender: {
    male: '#0080A8',
    female: '#942352',
  },
  info: '#4a4a4a',
  success: '#4caf50',
  warning: '#dbb702',
  error: '#942323',
  text: {
    disabled: '#474747',
    onDark: 'white',
    onWhite: '#5a5a5a',
    body: '#3A3A3A',
  },
};

export const mainFont = 'EBGaramond';
export const h1: TextStyle = {
  fontFamily: mainFont,
  fontSize: 24,
  color: colors.text.onDark,
};

export const h2: TextStyle = {
  fontFamily: mainFont,
  fontSize: 20,
  color: colors.text.onDark,
};

export const bodyText: TextStyle = {
  fontFamily: mainFont,
  fontSize: 18,
  color: colors.text.body,
};

export const margin = {
  big: 16,
  mid: 8,
  small: 4,
};

export const border = {
  radius: {
    mid: 8,
    big: 16,
  },
  width: 4,
};

export const shadow = {
  shadowOffset: {
    width: 4,
    height: 4,
  },
  shadowOpacity: 0.25,
  shadowRadius: 1,
};

export const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    // setting background color for the whole app to reduce flickering on navigation
    background: colors.secondary.dark,
  },
};
