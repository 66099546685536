import { colors, mainFont } from '../styles';
import { KeyboardTypeOptions } from 'react-native/Libraries/Components/TextInput/TextInput';
import { Input } from '@rneui/themed';

export interface AppInputProps {
  placeholder?: string;
  value?: string;
  onChangeText?: (text: string) => void;
  secureTextEntry?: boolean;
  errorMessage?: string;
  focus?: boolean;
  keyboardType?: KeyboardTypeOptions;
}

export default function AppInput({ focus, ...rest }: AppInputProps) {
  return (
    <Input
      containerStyle={{
        paddingRight: 0,
        paddingLeft: 0,
      }}
      inputStyle={{ paddingLeft: 8, fontFamily: mainFont, height: 50 }}
      inputContainerStyle={{
        borderWidth: 1,
        borderColor: colors.primary.dark,
        borderRadius: 5,
        backgroundColor: colors.secondary.dark,
        width: '100%',
        height: 50,
      }}
      placeholderTextColor={colors.primary.light}
      autoFocus={focus}
      {...rest}
    />
  );
}
