import { useEffect } from 'react';
import { BackHandler } from 'react-native';

export default function useBackPressHandler(handler: () => void) {
  const actualHandler = () => {
    handler();
    return true;
  };

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', actualHandler);
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', actualHandler);
    };
  }, []);
}
