import { StyleSheet, Text, View } from 'react-native';
import i18n from '../../utils/localization';
import React, { useState } from 'react';
import SettingsItem from './SettingsItem';
import { storage, StorageKeys } from '../../utils/storage';
import { colors, h2, margin } from '../../styles';
import AppOverlay from '../../components/AppOverlay';
import AppInput from '../../components/AppInput';
import { getNameValidationError, validate } from '../../services/validators';
import { AppIcon } from '../../components/AppIcon';

export default function NameEditor() {
  const [visibleName, setVisibleName] = useState(storage.getString(StorageKeys.USER_NAME));
  const [showEditor, setShowEditor] = useState(false);

  const [editableName, setEditableName] = useState(visibleName!);
  const [nameError, setNameError] = useState<string | undefined>();

  function validateName(value: string): boolean {
    return validate(value, getNameValidationError, setNameError);
  }

  function onChangeTextHandler(newValue: string): void {
    setEditableName(newValue);
    validateName(newValue);
  }

  function handleSave(): void {
    if (validateName(editableName)) {
      storage.set(StorageKeys.USER_NAME, editableName);
      setVisibleName(editableName.trim());
      setShowEditor(false);
    }
  }

  return (
    <SettingsItem startText={`${i18n.t('playerName')}:`} onPress={() => setShowEditor(true)}>
      <AppOverlay
        visible={showEditor}
        onClose={() => setShowEditor(false)}
        headerText={i18n.t('enterYourName')}
        onOk={handleSave}
        okButtonShow={true}
        okButtonLabel={i18n.t('save')}
        okButtonDisabled={!!nameError || editableName.length === 0}
      >
        <View style={styles.overlayContainer}>
          <AppInput
            placeholder={i18n.t('enterName')}
            onChangeText={onChangeTextHandler}
            value={editableName}
            errorMessage={nameError ? i18n.t(nameError) : undefined}
            focus
          />
        </View>
      </AppOverlay>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={styles.playerNameText}>{visibleName}</Text>
        <AppIcon name={'create-outline'} size={'small'} color={colors.primary.regular} />
      </View>
    </SettingsItem>
  );
}

const styles = StyleSheet.create({
  playerNameText: {
    ...h2,
    color: colors.primary.regular,
    marginBottom: margin.small,
    marginRight: margin.mid,
  },
  overlayContainer: {
    width: 320,
    marginHorizontal: margin.mid,
  },
});
