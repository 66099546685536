import { Overlay, Text } from '@rneui/themed';
import { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { border, colors, h1, margin } from '../styles';
import AppButton from './AppButton';
import i18n from '../utils/localization';

export interface AppOverlayProps {
  children?: ReactNode;
  visible: boolean;
  onCloseLabel?: string;
  okButtonShow?: boolean;
  okButtonLabel?: string;
  okButtonDisabled?: boolean;
  onClose?: () => void;
  onOk?: () => void;
  headerText?: string;
}

export default function AppOverlay(props: AppOverlayProps) {
  return (
    <Overlay
      isVisible={props.visible}
      onBackdropPress={props.onClose}
      style={styles.main}
      overlayStyle={styles.overlay}
    >
      {props.headerText && <Text style={styles.header}>{props.headerText}</Text>}
      {props.children}
      <View style={styles.bottomMenu}>
        <View style={styles.button}>
          <AppButton onPress={props.onClose} type={'danger'}>
            {props.onCloseLabel ?? i18n.t('close')}
          </AppButton>
        </View>

        {props.okButtonShow && (
          <View style={styles.button}>
            <AppButton onPress={props.onOk} disabled={props.okButtonDisabled}>
              {props.okButtonLabel ?? 'Ok'}
            </AppButton>
          </View>
        )}
      </View>
    </Overlay>
  );
}

const styles = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  overlay: {
    backgroundColor: colors.secondary.light,
    borderRadius: border.radius.big,
    borderColor: colors.secondary.dark,
    borderWidth: border.width,
    minWidth: 360,
  },
  header: { ...h1, color: colors.primary.regular, textAlign: 'center', marginBottom: margin.mid },
  bottomMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    marginTop: margin.mid,
    flex: 1,
    marginHorizontal: 5,
  },
});
