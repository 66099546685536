import { Pressable, StyleSheet, Text } from 'react-native';
import { colors, h2 } from '../../styles';
import { ReactNode } from 'react';

interface SettingsItemProps {
  startText: string;
  children: ReactNode;
  onPress: () => void;
}

export default function SettingsItem({ startText, children, onPress }: SettingsItemProps) {
  return (
    <Pressable
      onPress={onPress}
      style={(pressableState) => ({
        ...styles.container,
        backgroundColor: pressableState.pressed ? colors.secondary.dark : undefined,
      })}
    >
      <Text style={styles.text}>{startText}</Text>
      {children}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  text: {
    ...h2,
    color: colors.primary.regular,
  },
});
