import i18n from '../../utils/localization';
import { StyleSheet, View } from 'react-native';
import AppInput from '../../components/AppInput';
import AppOverlay from '../../components/AppOverlay';
import { margin } from '../../styles';
import { useEffect, useState } from 'react';
import { getNameValidationError, validate } from '../../services/validators';

interface InputNameOverlayProps {
  isOpened: boolean;
  headerText: string;
  previousName?: string;
  onSave: (newName: string) => void;
  setIsOpened: (value: boolean) => void;
}

export default function InputNameOverlay({
  headerText,
  previousName = '',
  onSave,
  isOpened,
  setIsOpened,
}: InputNameOverlayProps) {
  const [playerName, setPlayerName] = useState<string>('');
  const [playerNameError, setPlayerNameError] = useState<string>();

  useEffect(() => {
    setPlayerName(previousName);
  }, [previousName, isOpened]);

  function onPlayerNameChange(newValue: string): void {
    validate(newValue, getNameValidationError, setPlayerNameError);
    setPlayerName(newValue);
  }

  return (
    <AppOverlay
      visible={isOpened}
      onClose={() => {
        setIsOpened(false);
        setPlayerName('');
        setPlayerNameError(undefined);
      }}
      onOk={() => {
        onSave(playerName);
        setPlayerName('');
        setPlayerNameError(undefined);
        setIsOpened(false);
      }}
      headerText={headerText}
      okButtonShow={true}
      okButtonLabel={i18n.t('save')}
      okButtonDisabled={!!playerNameError || playerName.length === 0}
    >
      <View style={styles.mainContainer}>
        <AppInput
          placeholder={i18n.t('playerName')}
          value={playerName}
          onChangeText={(value) => onPlayerNameChange(value)}
          errorMessage={playerNameError ? i18n.t(playerNameError) : undefined}
          focus
        />
      </View>
    </AppOverlay>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    width: 320,
    marginHorizontal: margin.mid,
  },
});
