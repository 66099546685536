import { ScrollView, StyleSheet, View } from 'react-native';
import { Text } from '@rneui/base';
import { useEffect, useState } from 'react';
import { JoinRoomDto } from '../../models/join-room-dto';
import { RoomState } from '../../models/room-state';
import {
  getAddMeAsPlayerPatch,
  getAddOfflinePlayerPatch,
  getChangeAdminRightsPatch,
  getChangeGenderPatch,
  getChangePlayerLevelPatch,
  getChangePlayerNamePatch,
  getKickPlayerPatch,
  getUpdatePlayerPatch,
} from '../../services/state-patcher';
import { JoinDto, KickDto, PatchDto } from 'commons';
import { storage, StorageKeys } from '../../utils/storage';
import PanelScreen from '../../components/PanelScreen';
import { AppIcon } from '../../components/AppIcon';
import { RoomScreenProps, ScreenNames } from '../../components/AppNavigator';
import useBackPressHandler from '../../hooks/useBackPressHandler';
import ManualPlayerAdding from './ManualPlayerAdding';
import QrOverlay from './QrOverlay';
import socket from '../../services/room-socket-gateway';
import { PlayerItem } from './PlayerItem';
import { h1, margin } from '../../styles';
import { Toast } from 'react-native-toast-notifications';
import i18n from '../../utils/localization';
import AppBlinkAnimation from '../../components/AppBlinkAnimation';
import GoBackConfirmationOverlay from './GoBackConfirmationOverlay';
import InputNameOverlay from './InputNameOverlay';
import ArrowBack from '../../components/ArrowBack';

export interface RoomScreenRouteParams {
  joinRoomDto: JoinRoomDto;
  initialRoomState?: RoomState;
}

export default function RoomScreen({ navigation, route }: RoomScreenProps) {
  const { joinRoomDto, initialRoomState } = route.params;
  const { pin } = joinRoomDto;

  storage.set(StorageKeys.LAST_JOINED_ROOM_INFO, JSON.stringify(route.params));

  const [roomState, setRoomState] = useState<RoomState | undefined>(initialRoomState);
  const [isQrOverlayOpened, setIsQrOverlayOpened] = useState(false);
  const [isGoBackConfirmationOpened, setIsGoBackConfirmationOpened] = useState(false);
  const [changeNameOverlayOpened, setChangeNameOverlayOpened] = useState(false);
  const [changeNamePlayerId, setChangeNamePlayerId] = useState<string>();
  const [changeNamePlayerName, setChangeNamePlayerName] = useState<string>();
  const [qrOverlayHasBeenOpenedEver, setQrOverlayHasBeenOpenedEver] = useState(
    !!storage.getBoolean(StorageKeys.FIRST_ROOM_CREATED)
  );

  const goBack = () => setIsGoBackConfirmationOpened(true);

  useBackPressHandler(goBack);

  useEffect(() => {
    socket.onStateUpdate = setRoomState;
    socket.onJoin = onJoin;
    socket.onKick = onKick;
    socket.onConnect = joinRoom;
    if (!socket.isConnected() && !socket.isConnecting()) {
      socket.connect();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', () => {
      storage.delete(StorageKeys.LAST_JOINED_ROOM_INFO);
      if (socket.isConnected()) socket.disconnect();
      unsubscribe();
    });
    return () => {
      unsubscribe(); // Remove the listener when the component unmounts
    };
  }, [navigation]);

  function joinRoom(): void {
    socket.join({
      pin: pin,
      userId: storage.getString(StorageKeys.PLAYER_ID)!,
    } as JoinDto);
  }

  function onJoin(data: RoomState): void {
    const playerId = storage.getString(StorageKeys.PLAYER_ID)!;
    const playerName = storage.getString(StorageKeys.USER_NAME)!;
    const playerIsInTheRoom = data.players.some((player) => player.id === playerId);

    const patch = playerIsInTheRoom ? getUpdatePlayerPatch(data, playerId, playerName) : getAddMeAsPlayerPatch(data);

    console.info(patch);
    socket.patch({
      pin,
      patch,
    } as PatchDto);
  }

  function onKick(playerId: string): void {
    if (storage.getString(StorageKeys.PLAYER_ID)! === playerId) {
      navigation.replace(ScreenNames.MainScreen);
      Toast.show(i18n.t('roomKickOutWarning'), { type: 'warning' });
      console.warn('User was kicked from the room');
      socket.disconnect();
    }
  }

  function onLevelChange(playerId: string, increment: number) {
    if (!roomState) return;
    const playerToChange = roomState.players.find((player) => player.id === playerId);
    if (playerToChange?.level === 1 && increment === -1) return;
    if (playerToChange?.level === 20 && increment === 1) return;
    const patch = getChangePlayerLevelPatch(roomState, playerId, increment);
    console.info(patch);
    socket.patch({
      pin,
      patch,
    } as PatchDto);
  }

  function onGenderChange(playerId: string, newGenderIsMale: boolean) {
    if (!roomState) return;
    const player = roomState.players.find((player) => player.id === playerId);
    if (!player || player.isMale === newGenderIsMale) return;
    const patch = getChangeGenderPatch(roomState, playerId, newGenderIsMale);
    console.info(patch);
    socket.patch({
      pin: pin,
      patch: patch,
    } as PatchDto);
  }

  function addNewAdmin(playerId: string) {
    if (!roomState) return;
    const player = roomState.players.find((player) => player.id === playerId);
    if (!player || player.isAdmin) return;
    const patch = getChangeAdminRightsPatch(roomState, playerId);
    console.info(patch);
    socket.patch({
      pin: pin,
      patch: patch,
    } as PatchDto);
  }

  function kickOut(playerId: string) {
    if (!roomState) return;
    socket.kick({
      playerId: playerId,
      pin: pin,
    } as KickDto);
    const patch = getKickPlayerPatch(roomState, playerId);
    console.info(patch);
    socket.patch({
      pin: pin,
      patch: patch,
    } as PatchDto);
  }

  function addOfflinePlayer(playerName: string) {
    if (!roomState) return;
    const patch = getAddOfflinePlayerPatch(roomState, playerName);
    console.info(patch);
    socket.patch({
      pin: pin,
      patch: patch,
    } as PatchDto);
  }

  function changePlayerName(newName: string) {
    if (!roomState || !changeNamePlayerId) return;
    const patch = getChangePlayerNamePatch(roomState, changeNamePlayerId, newName);
    console.info(patch);
    socket.patch({
      pin: pin,
      patch: patch,
    } as PatchDto);
  }

  const meAdmin =
    roomState?.players?.find((player) => player.id === storage.getString(StorageKeys.PLAYER_ID)!)?.isAdmin ?? false;

  const playerList = roomState?.players?.map((player, i) => (
    <PlayerItem
      key={i}
      player={player}
      showMoreButton={meAdmin}
      onLevelInc={(inc) => onLevelChange(player.id, inc)}
      onGenderChange={(isMale) => onGenderChange(player.id, isMale)}
      onAddNewAdmin={() => addNewAdmin(player.id)}
      onKickOut={() => kickOut(player.id)}
      onChangeName={() => {
        setChangeNamePlayerName(player.name);
        setChangeNamePlayerId(player.id);
        setChangeNameOverlayOpened(true);
      }}
    />
  ));

  const header = (
    <>
      <View style={styles.headerMenu}>
        <ArrowBack onPress={goBack} />
      </View>
      <View>
        <Text style={h1}>{i18n.t('yourGame').toUpperCase()}</Text>
      </View>
      <View style={styles.headerMenu}>
        <AppBlinkAnimation active={!qrOverlayHasBeenOpenedEver}>
          <AppIcon
            name="qr-code-scanner"
            size="medium"
            onPress={(): void => {
              setIsQrOverlayOpened(!isQrOverlayOpened);
              setQrOverlayHasBeenOpenedEver(true);
              storage.set(StorageKeys.FIRST_ROOM_CREATED, true);
            }}
          />
        </AppBlinkAnimation>
        <QrOverlay isQrOverlayOpened={isQrOverlayOpened} setIsQrOverlayOpened={setIsQrOverlayOpened} pin={pin} />
        <GoBackConfirmationOverlay
          isOpened={isGoBackConfirmationOpened}
          onCancel={() => setIsGoBackConfirmationOpened(false)}
          onConfirm={() => navigation.replace(ScreenNames.MainScreen)}
        />
        <InputNameOverlay
          headerText={i18n.t('inputNewName')}
          previousName={changeNamePlayerName}
          onSave={changePlayerName}
          isOpened={changeNameOverlayOpened}
          setIsOpened={setChangeNameOverlayOpened}
        />
        <AppIcon
          name="settings"
          size="medium"
          onPress={() =>
            navigation.replace(ScreenNames.SettingsScreen, {
              previousScreen: ScreenNames.RoomScreen,
              previousScreenParams: route.params,
            })
          }
        />
      </View>
    </>
  );

  const body = (
    <>
      <ScrollView style={{ flex: 1, marginBottom: margin.mid }}>{playerList}</ScrollView>
      <ManualPlayerAdding onAddNewPlayer={(name: string) => addOfflinePlayer(name)}></ManualPlayerAdding>
    </>
  );

  return <PanelScreen header={header} body={body} />;
}

const styles = StyleSheet.create({
  headerMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 78,
  },
});
