import { ReactNode, useEffect, useRef } from 'react';
import { Animated } from 'react-native';

export interface AppBlinkAnimationProps {
  children: ReactNode;
  active: boolean;
  minOpacity?: number;
  maxOpacity?: number;
}

export default function AppBlinkAnimation({
  children,
  active,
  minOpacity = 0.2,
  maxOpacity = 1,
}: AppBlinkAnimationProps) {
  const animationProgress = useRef(new Animated.Value(maxOpacity)).current;
  if (!active) animationProgress.resetAnimation();

  useEffect(() => {
    if (!active) return;
    Animated.loop(
      Animated.sequence([
        Animated.spring(animationProgress, { toValue: minOpacity, useNativeDriver: true }),
        Animated.spring(animationProgress, { toValue: maxOpacity, useNativeDriver: true }),
      ])
    ).start();
  }, []);

  return <Animated.View style={{ opacity: animationProgress }}>{children}</Animated.View>;
}
