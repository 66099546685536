import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import WelcomeScreen from '../screens/WelcomeScreen';
import MainScreen from '../screens/MainScreen';
import RoomScreen, { RoomScreenRouteParams } from '../screens/RoomScreen/RoomScreen';
import QRCodeScanScreen from '../screens/QRCodeScanScreen';
import React from 'react';
import { isRequiredKeysInitialized, storage, StorageKeys } from '../utils/storage';
import ManualInputConnectionScreen from '../screens/ManualInputConnectionScreen/ManualInputConnectionScreen';
import { theme } from '../styles';
import SettingsScreen, { SettingsScreenRouteParams } from '../screens/SettingsScreen/SettingsScreen';

export const enum ScreenNames {
  WelcomeScreen = 'WelcomeScreen',
  MainScreen = 'MainScreen',
  RoomScreen = 'RoomScreen',
  QRCodeScanScreen = 'QRCodeScanScreen',
  ManualInputConnectionScreen = 'ManualInputConnectionScreen',
  SettingsScreen = 'SettingsScreen',
}

type RootStackParamList = {
  WelcomeScreen: undefined;
  MainScreen: undefined;
  RoomScreen: RoomScreenRouteParams;
  QRCodeScanScreen: undefined;
  ManualInputConnectionScreen: undefined;
  SettingsScreen: SettingsScreenRouteParams;
};

export type WelcomeScreenProps = NativeStackScreenProps<RootStackParamList, ScreenNames.WelcomeScreen>;
export type MainScreenScreenProps = NativeStackScreenProps<RootStackParamList, ScreenNames.MainScreen>;
export type RoomScreenProps = NativeStackScreenProps<RootStackParamList, ScreenNames.RoomScreen>;
export type QRCodeScanScreenProps = NativeStackScreenProps<RootStackParamList, ScreenNames.QRCodeScanScreen>;
export type ManualInputConnectionScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ScreenNames.ManualInputConnectionScreen
>;
export type SettingsScreenProps = NativeStackScreenProps<RootStackParamList, ScreenNames.SettingsScreen>;

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function AppNavigator() {
  const [initialRoute, roomScreenRouteParams] = resolveInitialRoute();
  const defaultOptions = { headerShown: false };

  return (
    <NavigationContainer theme={theme}>
      <Stack.Navigator initialRouteName={initialRoute}>
        <Stack.Screen name={ScreenNames.WelcomeScreen} component={WelcomeScreen} options={defaultOptions} />
        <Stack.Screen name={ScreenNames.MainScreen} component={MainScreen} options={defaultOptions} />
        <Stack.Screen
          name={ScreenNames.RoomScreen}
          component={RoomScreen}
          options={defaultOptions}
          initialParams={roomScreenRouteParams}
        />
        <Stack.Screen name={ScreenNames.QRCodeScanScreen} component={QRCodeScanScreen} options={defaultOptions} />
        <Stack.Screen
          name={ScreenNames.ManualInputConnectionScreen}
          component={ManualInputConnectionScreen}
          options={defaultOptions}
        />
        <Stack.Screen name={ScreenNames.SettingsScreen} component={SettingsScreen} options={defaultOptions} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

function resolveInitialRoute(): [ScreenNames, RoomScreenRouteParams] | [ScreenNames] {
  const requiredKeysInitialized = isRequiredKeysInitialized();
  const lastJoinedRoom = tryFindLastJoinedRoom();
  if (requiredKeysInitialized && lastJoinedRoom) {
    return [ScreenNames.RoomScreen, lastJoinedRoom];
  }
  return requiredKeysInitialized ? [ScreenNames.MainScreen] : [ScreenNames.WelcomeScreen];
}

function tryFindLastJoinedRoom(): RoomScreenRouteParams | null {
  const roomScreenRouteParamsJson = storage.getString(StorageKeys.LAST_JOINED_ROOM_INFO);
  if (roomScreenRouteParamsJson) {
    const roomScreenRouteParams: RoomScreenRouteParams = JSON.parse(roomScreenRouteParamsJson);
    if (roomScreenRouteParams) {
      return roomScreenRouteParams;
    }
  }
  return null;
}
