import { ImageBackground, StyleSheet, View } from 'react-native';
import { margin, colors, border } from '../styles';
import { ReactNode } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';

export interface PanelScreenProps {
  header: ReactNode;
  body: ReactNode;
}

export default function PanelScreen({ header, body }: PanelScreenProps) {
  return (
    <View style={{ backgroundColor: colors.primary.dark, flexGrow: 1 }}>
      <ImageBackground style={styles.background} source={require('../assets/woodPlankTexture.png')} resizeMode="repeat">
        <SafeAreaView style={styles.mainContainer}>
          <View style={styles.header}>{header}</View>
          <View style={styles.body}>{body}</View>
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  background: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
  },
  mainContainer: {
    flex: 1,
    maxWidth: 600,
    display: 'flex',
  },
  header: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: margin.big,
  },
  body: {
    flexGrow: 1,
    padding: margin.big,
    backgroundColor: colors.secondary.regular,
    borderRadius: border.radius.big,
    borderColor: colors.secondary.dark,
    borderWidth: border.width,
  },
});
