import { io } from 'socket.io-client';
import env from '../env.json';
import { RoomState } from '../models/room-state';
import { JoinDto, KickDto, PatchDto } from '@commons/index';

export interface RoomSocketGateway {
  onStateUpdate: (roomState: RoomState) => void;
  onKick: (playerId: string) => void;
  onError: (error: string) => void;
  onJoin: (state: RoomState) => void;
  onConnect: () => void;

  join(dto: JoinDto): void;
  patch(dto: PatchDto): void;
  kick(dto: KickDto): void;

  isConnecting(): boolean;
  isConnected(): boolean;
  connect(): void;
  disconnect(): void;
}

const socket = io(env.url, {
  autoConnect: false,
});

class Gateway implements RoomSocketGateway {
  onStateUpdate = (roomState: RoomState) => {};
  onKick = (_: string) => {};
  onError = (_: string) => {};
  onJoin = (_: RoomState) => {};
  onConnect = () => {};

  join(dto: JoinDto): void {
    console.info('Sending join');
    socket.emit('join', dto);
  }

  patch(dto: PatchDto): void {
    console.info('Sending patch');
    socket.emit('patch', dto);
  }

  kick(dto: KickDto): void {
    console.info('Sending kick');
    socket.emit('kick', dto);
  }

  private _connecting = false;
  isConnecting(): boolean {
    return this._connecting;
  }

  isConnected() {
    return socket.connected;
  }

  connect() {
    if (socket.connected) {
      console.info('trying to connect when already connected');
      return;
    } else if (this._connecting) {
      console.info('trying to connect when already connecting');
    } else {
      console.info('trying to connect');
    }
    this._connecting = true;
    socket.connect();

    socket.removeAllListeners();

    socket.on('connect', () => {
      console.log('Connected to Socket.io server');
      socket.on('connect_error', () => {
        console.log('Connection error');
        this._connecting = false;
      });
      socket.on('onStateUpdate', (data: RoomState) => {
        console.log('Received onStateUpdate', data);
        this.onStateUpdate(data);
      });
      socket.on('kick', (playerId: string) => {
        console.log('Received kick');
        this.onKick(playerId);
      });
      socket.on('onError', (error: string) => {
        console.log('Received onError', error);
        this.onError(error);
      });
      socket.on('join', (state: RoomState) => {
        console.log('Received join', state);
        this.onJoin(state);
      });
      socket.on('disconnect', () => {
        console.log('A user disconnected');
        socket.offAny();
      });
      this.onConnect();
      this._connecting = false;
    });
  }

  disconnect(): void {
    console.log('disconnecting');
    socket.disconnect();
  }
}

const instance = new Gateway();

export default instance;
