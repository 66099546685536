import { I18n } from 'i18n-js';
import * as Localization from 'expo-localization';
import { storage, StorageKeys } from './storage';

interface TranslationStructure {
  'Pin must be 6 symbols': string;
  mainMenu: string;
  gender: string;
  makeAnAdmin: string;
  save: string;
  language: string;
  greetings: string;
  password: string;
  keepDisplayOn: string;
  create: string;
  join: string;
  enterName: string;
  close: string;
  connect: string;
  settings: string;
  manualInput: string;
  cameraPermissionNotGranted: string;
  kickOut: string;
  playerName: string;
  errorJoiningRoom: string;
  loading: string;
  version: string;
  roomPin: string;
  'Name is required': string;
  'Pin is required': string;
  noInternetConnection: string;
  internalServerError: string;
  roomKickOutWarning: string;
  somethingWentWrong: string;
  addOfflinePlayer: string;
  enterYourName: string;
  continue: string;
  versionCopied: string;
  yourGame: string;
  pinCopied: string;
  leaveConfirmation: string;
  cancel: string;
  confirm: string;
  changeName: string;
  inputNewName: string;
  privacyPolicy: string;
}

type Translations = { [key: string]: TranslationStructure };

// Set the key-value pairs for the different languages you want to support.
const translations: Translations = {
  en: {
    greetings: 'GREETINGS ADVENTURER!',
    create: 'Create',
    join: 'Join',
    mainMenu: 'Main menu',
    makeAnAdmin: 'Make an admin',
    kickOut: 'Kick out',
    enterName: 'Enter a name',
    settings: 'Settings',
    playerName: 'Player name',
    gender: 'Sex',
    keepDisplayOn: 'Keep display on',
    version: 'Version',
    close: 'Close',
    save: 'Save',
    manualInput: 'Input manually',
    loading: 'Loading...',
    errorJoiningRoom: 'Error joining room',
    cameraPermissionNotGranted: 'Camera permission not granted',
    roomPin: 'Room PIN',
    password: 'Password',
    connect: 'Connect',
    language: 'Language',
    'Name is required': 'Name is required',
    'Pin is required': 'Room PIN is required',
    'Pin must be 6 symbols': 'Room PIN must be 6 symbols',
    noInternetConnection: 'No internet connection',
    internalServerError: 'Internal server error',
    roomKickOutWarning: 'You have been kicked out the room',
    somethingWentWrong: 'Something went wrong',
    addOfflinePlayer: 'Add offline player',
    enterYourName: 'Enter your name',
    continue: 'Continue',
    versionCopied: 'Version copied to clipboard',
    yourGame: 'Your game',
    pinCopied: 'PIN copied to clipboard',
    leaveConfirmation: 'Are you sure you want to leave the room?',
    cancel: 'Cancel',
    confirm: 'Confirm',
    changeName: 'Change name',
    inputNewName: 'Input new name',
    privacyPolicy: 'Privacy policy',
  },
  ru: {
    greetings: 'ПРИВЕТСТВУЮ АВАНТЮРИСТ!',
    create: 'Создать',
    join: 'Присоединиться',
    mainMenu: 'Главное меню',
    makeAnAdmin: 'Сделать админом',
    kickOut: 'Выгнать',
    enterName: 'Введите имя',
    settings: 'Настройки',
    playerName: 'Имя игрока',
    gender: 'Пол',
    keepDisplayOn: 'Не выключать экран',
    version: 'Версия',
    close: 'Закрыть',
    save: 'Сохранить',
    manualInput: 'Ручной ввод',
    loading: 'Загрузка...',
    errorJoiningRoom: 'Не удалось присоединиться к комнате',
    cameraPermissionNotGranted: 'Разрешение на камеру не выдано',
    roomPin: 'PIN комнаты',
    password: 'Пароль',
    connect: 'Подключиться',
    language: 'Язык',
    'Name is required': 'Имя обязательно',
    'Pin is required': 'PIN комнаты обязателен',
    'Pin must be 6 symbols': 'PIN комнаты должен быть 6 символов',
    noInternetConnection: 'Нет интернет соединения',
    internalServerError: 'Внутренняя ошибка сервера',
    roomKickOutWarning: 'Вас выгнали из комнаты',
    somethingWentWrong: 'Что-то пошло не так',
    addOfflinePlayer: 'Добавить оффлайн игрока',
    enterYourName: 'Введите ваше имя',
    continue: 'Продолжить',
    versionCopied: 'Версия скопирована в буфер обмена',
    yourGame: 'Ваша игра',
    pinCopied: 'PIN скопирован в буфер обмена',
    leaveConfirmation: 'Вы уверены, что хотите покинуть комнату?',
    cancel: 'Отмена',
    confirm: 'Подтвердить',
    changeName: 'Изменить имя',
    inputNewName: 'Введите новое имя',
    privacyPolicy: 'Политика конфиденциальности',
  },
  uk: {
    greetings: 'ВІТАЮ ШУКАЧА ПРИГОД!',
    create: 'СТВОРИТИ',
    join: 'Приєднатись',
    mainMenu: 'Головне меню',
    makeAnAdmin: 'Зробити адміном',
    kickOut: 'Вигнати',
    enterName: "Введіть ім'я",
    settings: 'Налаштування',
    playerName: "Ім'я гравця",
    gender: 'Стать',
    keepDisplayOn: 'Не вимикати екран',
    version: 'Версія',
    close: 'Закрити',
    save: 'Зберегти',
    manualInput: 'Ручне введення',
    loading: 'Завантаження...',
    errorJoiningRoom: 'Не вдалося приєднатися до кімнати',
    cameraPermissionNotGranted: 'Дозвіл на камеру не надано',
    roomPin: 'PIN кімнати',
    password: 'Пароль',
    connect: 'Підключитиcь',
    language: 'Мова',
    'Name is required': "Ім'я обов'язкове",
    'Pin is required': "PIN кімнати обов'язковий",
    'Pin must be 6 symbols': 'PIN кімнати повинен бути 6 символів',
    noInternetConnection: "Немає інтернет з'єднання",
    internalServerError: 'Внутрішня помилка сервера',
    roomKickOutWarning: 'Вас вигнали з кімнати',
    somethingWentWrong: 'Щось пішло не так',
    addOfflinePlayer: 'Додати офлайн гравця',
    enterYourName: "Введіть ваше ім'я",
    continue: 'Продовжити',
    versionCopied: 'Версія скопійована в буфер обміну',
    yourGame: 'Ваша гра',
    pinCopied: 'PIN скопійований в буфер обміну',
    leaveConfirmation: 'Ви впевнені, що хочете покинути кімнату?',
    cancel: 'Скасувати',
    confirm: 'Підтвердити',
    changeName: "Змінити ім'я",
    inputNewName: "Введіть нове ім'я",
    privacyPolicy: 'Політика конфіденційності',
  },
};
const i18n = new I18n(translations);

// Set the locale once at the beginning of your app.
let locale = storage.getString(StorageKeys.LOCALE);
if (locale === undefined) {
  const languageCode = Localization.getLocales()[0].languageCode;
  storage.set(StorageKeys.LOCALE, languageCode);
  locale = languageCode;
}
i18n.locale = locale;
i18n.enableFallback = true;

export default i18n;
