import { useState } from 'react';
import { ImageBackground, StyleSheet, Text, View } from 'react-native';
import AppInput from '../components/AppInput';
import { getNameValidationError, validate } from '../services/validators';
import { storage, StorageKeys } from '../utils/storage';
import { v4 as uuid } from 'uuid';
import { colors, h1, h2, margin } from '../styles';
import AppButton from '../components/AppButton';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ScreenNames, WelcomeScreenProps } from '../components/AppNavigator';
import i18n from '../utils/localization';

export default function WelcomeScreen({ navigation }: WelcomeScreenProps) {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | undefined>();

  const validateName = (value: string) => validate(value, getNameValidationError, setNameError);

  const continueHandler = () => {
    const isNameValid = validateName(name);
    if (!isNameValid) return;
    storage.set(StorageKeys.USER_NAME, name.trim());
    storage.set(StorageKeys.PLAYER_ID, uuid());
    navigation.navigate(ScreenNames.MainScreen);
  };

  const onChangeTextHandler = () => (newValue: string) => {
    setName(newValue);
    validateName(newValue);
  };

  return (
    <SafeAreaView style={styles.mainContainer}>
      <Text style={styles.greetingsHeader}>{i18n.t('greetings')}</Text>
      <View style={styles.formContainer}>
        <ImageBackground style={styles.image} source={require('../assets/warrior.png')} />
        <AppInput
          placeholder={i18n.t('enterYourName')}
          onChangeText={onChangeTextHandler()}
          value={name}
          errorMessage={nameError ? i18n.t(nameError) : undefined}
          focus
        />
        <AppButton onPress={continueHandler}>
          {i18n.t('continue')}
        </AppButton>
      </View>
      <View />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    backgroundColor: colors.secondary.regular,
    flexGrow: 1,
  },
  greetingsHeader: {
    ...h1,
    color: colors.primary.regular,
    marginTop: margin.small,
    textAlign: 'center',
  },
  formContainer: {
    maxWidth: 300,
  },
  image: {
    width: 300,
    height: 300,
  },
});
