import { ToastProps } from 'react-native-toast-notifications/lib/typescript/toast';
import { StyleSheet, Text, View } from 'react-native';
import { border, colors, margin } from '../styles';
import { AppIcon, IconName } from './AppIcon';

type ToastType = 'normal' | 'success' | 'danger' | 'warning';

interface ToastMapping {
  color: string;
  iconName: IconName;
}

const toastMappings = new Map<ToastType, ToastMapping>([
  ['normal', { color: colors.info, iconName: 'info-with-circle' }],
  ['success', { color: colors.success, iconName: 'check-circle' }],
  ['danger', { color: colors.error, iconName: 'close-circle-outline' }],
  ['warning', { color: colors.warning, iconName: 'warning' }],
]);

export default function AppToast(props: ToastProps) {
  const mapping = toastMappings.get(props.type as ToastType) ?? toastMappings.get('normal')!;
  return (
    <View
      style={{
        ...styles.toastBody,
        backgroundColor: mapping.color,
      }}
    >
      <AppIcon name={mapping.iconName} size="medium" />
      <Text style={styles.toastText}>{props.message}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  toastBody: {
    padding: margin.big,
    width: '95%',
    maxWidth: 570,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: border.radius.mid,
    marginBottom: margin.mid,
  },
  toastText: {
    fontSize: 16,
    marginHorizontal: margin.big,
    color: colors.text.onDark,
  },
});