import SettingsItem from './SettingsItem';
import i18n from '../../utils/localization';
import { AppIcon } from '../../components/AppIcon';
import { colors } from '../../styles';
import React, { useState } from 'react';
import { privacyPolicyUrl } from '../../env.json';
import WebView from 'react-native-webview';
import AppOverlay from '../../components/AppOverlay';

export default function PrivacyPolicy() {
  const [isPolicyVisible, setIsPolicyVisible] = useState(false);

  return (
    <SettingsItem startText={`${i18n.t('privacyPolicy')}`} onPress={() => setIsPolicyVisible(true)}>
      <AppIcon name={'infocirlceo'} size={'small'} color={colors.primary.regular} />
      <AppOverlay visible={isPolicyVisible} onClose={() => setIsPolicyVisible(false)}>
        <WebView source={{ uri: privacyPolicyUrl }} />
      </AppOverlay>
    </SettingsItem>
  );
}
