import { storage, StorageKeys } from '../utils/storage';
import { RoomScreenRouteParams } from '../screens/RoomScreen/RoomScreen';
import { getRoom } from './room-api';
import { RoomState } from '../models/room-state';

export default async function handleLastJoinedRoom(onFinally: () => void) {
  const lastJoinRoomInfoJson = storage.getString(StorageKeys.LAST_JOINED_ROOM_INFO);
  try {
    if (lastJoinRoomInfoJson) {
      const prevRoomScreenRouteParams: RoomScreenRouteParams = JSON.parse(lastJoinRoomInfoJson);
      const roomDto = await getRoom(prevRoomScreenRouteParams.joinRoomDto);
      const roomScreenRouteParams: RoomScreenRouteParams = {
        joinRoomDto: prevRoomScreenRouteParams.joinRoomDto,
        initialRoomState: roomDto.state as RoomState,
      };
      storage.set(StorageKeys.LAST_JOINED_ROOM_INFO, JSON.stringify(roomScreenRouteParams));
    }
  } catch (error) {
    storage.delete(StorageKeys.LAST_JOINED_ROOM_INFO);
  } finally {
    onFinally();
  }
}
