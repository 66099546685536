import { AppIcon } from './AppIcon';
import React from 'react';
import { useNavigation } from '@react-navigation/native';

interface ArrowBackProps {
  onPress?: () => void;
}

export default function ArrowBack({ onPress }: ArrowBackProps) {
  const navigation = useNavigation();
  return <AppIcon name="arrow-back" size="medium" onPress={onPress ?? (() => navigation.goBack())} />;
}
