import SettingsItem from './SettingsItem';
import i18n from '../../utils/localization';
import Constants from 'expo-constants';
import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { colors, h2, margin } from '../../styles';
import Clipboard from '@react-native-clipboard/clipboard';
import { Toast } from 'react-native-toast-notifications';
import { AppIcon } from '../../components/AppIcon';

export default function VersionItem() {
  const appVersion = Constants.expoConfig?.version ?? '';

  function onPress(): void {
    Toast.show(i18n.t('versionCopied'), { type: 'success' });
    return Clipboard.setString(appVersion);
  }

  return (
    <SettingsItem startText={`${i18n.t('version')}:`} onPress={onPress}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={styles.itemText}>{appVersion}</Text>
        <AppIcon name={'copy-outline'} size={'small'} color={colors.primary.regular} />
      </View>
    </SettingsItem>
  );
}

const styles = StyleSheet.create({
  itemText: {
    ...h2,
    color: colors.primary.regular,
    marginRight: margin.mid,
  },
});
