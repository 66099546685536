import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field';
import { StyleSheet, Text } from 'react-native';
import React from 'react';
import { border, colors, mainFont } from '../../styles';

interface PinInputProps {
  pin: string;
  setPin: (value: string) => void;
  inputChanged: () => void;
}

export default function PinInput({ pin, setPin, inputChanged }: PinInputProps) {
  const cellCount = 6;
  const pinInputRef = useBlurOnFulfill({ value: pin, cellCount: cellCount });
  const [pinInputProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value: pin,
    setValue: setPin,
  });

  const onPinInput = (value: string) => {
    if (isNaN(Number(value))) return;
    setPin(value);
    inputChanged();
  };

  return (
    <CodeField
      autoFocus
      ref={pinInputRef}
      {...pinInputProps}
      value={pin}
      onChangeText={onPinInput}
      cellCount={cellCount}
      keyboardType="number-pad"
      textContentType="oneTimeCode"
      renderCell={({ index, symbol, isFocused }) => {
        inputChanged();
        return (
          <Text
            key={index}
            style={[styles.cell, isFocused && styles.focusedCell]}
            onLayout={getCellOnLayoutHandler(index)}
          >
            {symbol || (isFocused ? <Cursor /> : null)}
          </Text>
        );
      }}
    />
  );
}

const styles = StyleSheet.create({
  cell: {
    width: 50,
    height: 50,
    lineHeight: 50,
    fontSize: 35,
    borderWidth: 2,
    borderColor: '#00000030',
    textAlign: 'center',
    borderRadius: border.radius.mid,
    fontFamily: mainFont,
    backgroundColor: colors.secondary.dark
  },
  focusedCell: {
    lineHeight: 43,
    borderColor: colors.primary.dark,
  },
});
