import { Text } from 'react-native';
import { AppIcon } from '../../components/AppIcon';
import { useState } from 'react';
import InputNameOverlay from './InputNameOverlay';
import i18n from '../../utils/localization';
import AppButton from '../../components/AppButton';
import { h2, margin } from '../../styles';

export interface ManualPlayerAddingProps {
  onAddNewPlayer: (name: string) => void;
}

export default function ManualPlayerAdding({ onAddNewPlayer }: ManualPlayerAddingProps) {
  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);
  return (
    <AppButton onPress={() => setIsOverlayOpened(true)}>
      <AppIcon name="plus" size="small" />
      <Text style={{ ...h2, marginHorizontal: margin.mid }}>{i18n.t('addOfflinePlayer')}</Text>
      <InputNameOverlay
        headerText={i18n.t('addOfflinePlayer')}
        onSave={onAddNewPlayer}
        isOpened={isOverlayOpened}
        setIsOpened={setIsOverlayOpened}
      />
    </AppButton>
  );
}
