import { storage, StorageKeys } from '../../utils/storage';
import { AppSwitch } from '../../components/AppSwitch';
import i18n from '../../utils/localization';
import { useKeepAwakeContext } from '../../components/KeepAwakeContext';
import SettingsItem from './SettingsItem';

export default function DisplayAlwaysOnSwitcher() {
  const { screenDisplayOn, setScreenDisplayOn } = useKeepAwakeContext();

  function toggleSwitch(): void {
    storage.set(StorageKeys.SCREEN_ALWAYS_ON, !screenDisplayOn);
    setScreenDisplayOn(!screenDisplayOn);
  }

  return (
    <SettingsItem onPress={toggleSwitch} startText={`${i18n.t('keepDisplayOn')}:`}>
      <AppSwitch value={screenDisplayOn} onValueChange={toggleSwitch}></AppSwitch>
    </SettingsItem>
  );
}
