import { ImageBackground, StyleSheet, View } from 'react-native';
import AppMenu from '../../components/AppMenu';
import { useState } from 'react';
import { Text } from '@rneui/base';
import { bodyText } from '../../styles';
import { storage, StorageKeys } from '../../utils/storage';
import i18n from '../../utils/localization';
import SettingsItem from './SettingsItem';

const images = new Map<string, any>([
  ['en', require('../../assets/en.png')],
  ['uk', require('../../assets/uk.png')],
  ['ru', require('../../assets/ru.png')],
]);

interface LocalizationPickerProps {
  locale: string;
  setLocale: (locale: string) => void;
}

export function LocalizationPicker({ locale, setLocale }: LocalizationPickerProps) {
  const [isVisible, setIsVisible] = useState(false);

  function updateLocale(locale: string): void {
    setLocale(locale);
    i18n.locale = locale;
    storage.set(StorageKeys.LOCALE, locale);
  }

  return (
    <SettingsItem onPress={() => setIsVisible(!isVisible)} startText={`${i18n.t('language')}:`}>
      <AppMenu
        options={['en', 'uk', 'ru'].map((locale) => {
          return {
            view: (
              <View style={styles.item}>
                <ImageBackground style={styles.flagPicker} source={images.get(locale)}></ImageBackground>
                <Text style={bodyText}>{locale.toUpperCase()}</Text>
              </View>
            ),
            onPress: () => {
              updateLocale(locale);
              setIsVisible(false);
            },
          };
        })}
        visible={isVisible}
        onRequestClose={() => setIsVisible(false)}
      >
        <ImageBackground style={styles.flagPicker} source={images.get(locale)} />
      </AppMenu>
    </SettingsItem>
  );
}

const styles = StyleSheet.create({
  flagPicker: {
    width: 32,
    height: 24,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 68,
  },
});
