import AppOverlay from '../../components/AppOverlay';
import QRCode from 'react-native-qrcode-svg';
import { convertToBase64 } from '../../services/password-hashing';
import { JoinRoomDto } from '../../models/join-room-dto';
import { bodyText, colors, h1 } from '../../styles';
import { Pressable, StyleSheet } from 'react-native';
import { Text } from '@rneui/base';
import i18n from '../../utils/localization';
import { AppIcon } from '../../components/AppIcon';
import React from 'react';
import { Toast } from 'react-native-toast-notifications';
import Clipboard from '@react-native-clipboard/clipboard';

interface QrOverlayProps {
  isQrOverlayOpened: boolean;
  setIsQrOverlayOpened: (value: boolean) => void;
  pin: string;
}

export default function QrOverlay({ isQrOverlayOpened, setIsQrOverlayOpened, pin }: QrOverlayProps) {
  function onPress(): void {
    Toast.show(i18n.t('pinCopied'), { type: 'success' });
    return Clipboard.setString(pin);
  }

  return (
    <AppOverlay visible={isQrOverlayOpened} onClose={() => setIsQrOverlayOpened(false)}>
      <QRCode
        value={convertToBase64(JSON.stringify({ pin } as JoinRoomDto))}
        quietZone={10}
        size={340}
        color={colors.primary.dark}
        backgroundColor={colors.secondary.light}
      />
      <Pressable
        style={(pressableState) => ({
          ...styles.credentialsRow,
          backgroundColor: pressableState.pressed ? colors.secondary.dark : undefined,
        })}
        onPress={onPress}
      >
        <Text style={styles.credentialsText}>
          {pin.substring(0, 3)} {pin.substring(3, 6)}{' '}
          <AppIcon name={'copy-outline'} size={'big'} color={colors.primary.regular} />
        </Text>
      </Pressable>
    </AppOverlay>
  );
}

const styles = StyleSheet.create({
  credentialsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  credentialsHeader: {
    ...bodyText,
    color: colors.primary.regular,
  },
  credentialsText: {
    ...h1,
    fontSize: 60,
    color: colors.primary.regular,
  },
});
