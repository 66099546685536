import { Switch } from '@rneui/base';
import { colors } from '../styles';

export interface AppSwitchProps {
  value: boolean;
  onValueChange: () => void;
}

export function AppSwitch(props: AppSwitchProps) {
  return <Switch value={props.value} onValueChange={props.onValueChange} color={colors.primary.regular} />;
}
