import React, { useState } from 'react';
import { getPinValidationError, validate } from '../../services/validators';
import { JoinRoomDto } from '../../models/join-room-dto';
import { getRoom } from '../../services/room-api';
import { ManualInputConnectionScreenProps, ScreenNames } from '../../components/AppNavigator';
import { StyleSheet, Text, View } from 'react-native';
import { bodyText, colors, h1, margin } from '../../styles';
import AppButton from '../../components/AppButton';
import PanelScreen from '../../components/PanelScreen';
import useBackPressHandler from '../../hooks/useBackPressHandler';
import i18n from '../../utils/localization';
import { Toast } from 'react-native-toast-notifications';
import PinInput from './PinInput';
import ArrowBack from '../../components/ArrowBack';

function changeButtonStateIfNeeded(
  pin: string,
  setIsButtonDisabled: (value: ((prevState: boolean) => boolean) | boolean) => void
) {
  const isPinValid = validate(pin, getPinValidationError, () => {});
  setIsButtonDisabled(!isPinValid);
}

export default function ManualInputConnectionScreen({ navigation }: ManualInputConnectionScreenProps) {
  const [pin, setPin] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const goBack = () => navigation.navigate(ScreenNames.MainScreen);

  useBackPressHandler(goBack);

  const inputChanged = () => changeButtonStateIfNeeded(pin, setIsButtonDisabled);
  const handleConnect = () => {
    const canConnect = validate(pin, getPinValidationError, () => {});

    if (canConnect) {
      const joinRoomDto: JoinRoomDto = {
        pin: pin?.trim()!,
      };
      getRoom(joinRoomDto)
        .then(() => navigation.navigate(ScreenNames.RoomScreen, { joinRoomDto }))
        .catch(() => Toast.show(i18n.t('errorJoiningRoom'), { type: 'danger' }));
    }
  };

  const header = (
    <>
      <View>
        <ArrowBack onPress={goBack} />
      </View>
      <View>
        <Text style={h1}>{i18n.t('join').toUpperCase()}</Text>
      </View>
      <View style={{ width: 28 }}></View>
    </>
  );
  const body = (
    <>
      <View style={styles.body}>
        <View style={styles.manualBlock}>
          <View>
            <PinInput pin={pin} setPin={setPin} inputChanged={inputChanged} />
          </View>
          <AppButton onPress={() => handleConnect()} disabled={isButtonDisabled}>
            {i18n.t('connect')}
          </AppButton>
        </View>
      </View>
    </>
  );
  return <PanelScreen body={body} header={header} />;
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  manualBlock: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bodyText: {
    ...bodyText,
    color: colors.primary.dark,
    marginTop: margin.mid,
  },
});
