import { storage, StorageKeys } from '../../utils/storage';
import { useState } from 'react';
import i18n from '../../utils/localization';
import SettingsItem from './SettingsItem';
import GenderSelector from '../RoomScreen/GenderSelector';

export default function SexSwitcher() {
  const [isGenderMale, setIsGenderMale] = useState(storage.getBoolean(StorageKeys.PLAYER_GENDER_IS_MALE)!);

  function updateSex(newGenderIsMale: boolean): void {
    storage.set(StorageKeys.PLAYER_GENDER_IS_MALE, newGenderIsMale);
    setIsGenderMale(newGenderIsMale);
  }

  return (
    <SettingsItem onPress={() => updateSex(!isGenderMale)} startText={`${i18n.t('gender')}:`}>
      <GenderSelector playerIsMale={isGenderMale} onGenderChange={updateSex} />
    </SettingsItem>
  );
}
