import { Button } from '@rneui/base';
import { ReactNode } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { colors, h2, margin } from '../styles';

type AppButtonType = 'primary' | 'danger';

export interface AppButtonProps {
  onPress?: () => void;
  children: ReactNode;
  buttonStyles?: ViewStyle;
  disabled?: boolean;
  type?: AppButtonType;
}

export default function AppButton({ children, buttonStyles, type = 'primary', ...rest }: AppButtonProps) {
  const styles = type === 'primary' ? primaryStyles : dangerStyles;
  const componentStyles = { ...styles.main, ...buttonStyles };
  return (
    <Button buttonStyle={componentStyles} disabledStyle={styles.disabled} titleStyle={h2} {...rest}>
      {children}
    </Button>
  );
}

const primaryStyles = StyleSheet.create({
  main: {
    height: 48,
    backgroundColor: colors.primary.regular,
    borderRadius: margin.small,
  },
  disabled: {
    opacity: 0.7,
    backgroundColor: colors.primary.regular,
  },
});

const dangerStyles = StyleSheet.create({
  main: {
    height: 48,
    backgroundColor: colors.error,
    borderRadius: margin.small,
  },
  disabled: {
    opacity: 0.7,
    backgroundColor: colors.error,
  },
});

