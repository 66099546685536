import 'react-native-get-random-values';
import { StatusBar } from 'expo-status-bar';
import * as SplashScreen from 'expo-splash-screen';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import handleLastJoinedRoom from './services/last-joined-room-handler';
import * as Font from 'expo-font';
import AppNavigator from './components/AppNavigator';
import { KeepAwakeProvider } from './components/KeepAwakeContext';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AppToastProvider from './components/AppToastProvider';
import { FileLogger } from 'react-native-file-logger';

SplashScreen.preventAutoHideAsync();

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    async function prepare() {
      try {
        await Font.loadAsync('EBGaramond', require('./assets/fonts/EBGaramond-Medium.ttf'));
        await handleLastJoinedRoom(() => {});
        await FileLogger.configure({ dailyRolling: true });
      } catch (e) {
        console.warn(e);
      } finally {
        setAppIsReady(true);
      }
    }
    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  return (
    <SafeAreaProvider>
      <KeepAwakeProvider>
        <StatusBar hidden={false} style="light" />
        <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
          <AppToastProvider>
            <AppNavigator />
          </AppToastProvider>
        </View>
      </KeepAwakeProvider>
    </SafeAreaProvider>
  );
}
