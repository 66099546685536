import { ReactNode } from 'react';
import { Text, View } from 'react-native';
import { Menu, MenuItem } from 'react-native-material-menu';
import { border, colors, margin } from '../styles';
import { AppIcon, IconName } from './AppIcon';

export interface AppMenuProps {
  onRequestClose?: () => void;
  options: AppMenuOption[];
  styles?: any;
  visible?: boolean;
  children: ReactNode;
}

export interface AppMenuOption {
  text?: string;
  iconName?: IconName;
  iconColor?: string;
  view?: ReactNode;
  skip?: boolean;
  onPress: () => void;
}

export default function AppMenu(props: AppMenuProps) {
  const menuItems = props.options.filter((x) => !x.skip).map((option, i) => {
    return (
      <MenuItem
        key={i}
        onPress={() => option.onPress()}
        pressColor={colors.secondary.dark}
      >
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {option.view}
          {option.iconName && !option.view && (
            <AppIcon name={option.iconName!} color={option.iconColor ?? colors.primary.regular} size="small"></AppIcon>
          )}
          {option.text && !option.view && <Text style={{ marginLeft: margin.small }}>{option.text}</Text>}
        </View>
      </MenuItem>
    );
  });

  return (
    <Menu
      visible={props.visible}
      anchor={props.children}
      onRequestClose={props.onRequestClose}
      style={{
        backgroundColor: colors.secondary.light,
        borderRadius: border.radius.big,
        borderColor: colors.primary.regular,
        borderWidth: 1,
      }}
    >
      {menuItems}
    </Menu>
  );
}
