import { BackHandler, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { createNewOnlineRoom } from '../services/room-api';
import { border, colors, h1, margin } from '../styles';
import { RoomDto } from 'commons';
import { JoinRoomDto } from '../models/join-room-dto';
import { RoomState } from '../models/room-state';
import { storage, StorageKeys } from '../utils/storage';
import PanelScreen from '../components/PanelScreen';
import { AppIcon } from '../components/AppIcon';
import { MainScreenScreenProps, ScreenNames } from '../components/AppNavigator';
import useBackPressHandler from '../hooks/useBackPressHandler';
import i18n from '../utils/localization';
import { Toast } from 'react-native-toast-notifications';
import { useNetInfo } from '@react-native-community/netinfo';

export default function MainScreen({ navigation }: MainScreenScreenProps) {
  const { isConnected: isInternetConnected } = useNetInfo();

  useBackPressHandler(() => BackHandler.exitApp());

  async function onCreate(): Promise<void> {
    try {
      const userName = storage.getString(StorageKeys.USER_NAME)!;
      const initialState: RoomState = {
        players: [
          {
            id: storage.getString(StorageKeys.PLAYER_ID)!,
            level: 1,
            name: userName,
            isMale: storage.getBoolean(StorageKeys.PLAYER_GENDER_IS_MALE)!,
            isAdmin: true,
            isOffline: false,
          },
        ],
      };
      const roomDto: RoomDto = await createNewOnlineRoom(userName, initialState);
      const joinRoomDto: JoinRoomDto = {
        pin: roomDto.pin,
      };
      navigation.navigate(ScreenNames.RoomScreen, { joinRoomDto });
    } catch (error) {
      if (!isInternetConnected) {
        Toast.show(i18n.t('noInternetConnection'), { type: 'danger' });
        console.error('No internet connection');
      } else {
        Toast.show(i18n.t('internalServerError'), { type: 'danger' });
        console.error('Internal server error on room creation: ', error);
      }
    }
  }

  const header = (
    <>
      <View style={{ width: 28 }}></View>
      <View>
        <Text style={h1}>{i18n.t('mainMenu').toUpperCase()}</Text>
      </View>
      <AppIcon
        name="settings"
        size="medium"
        onPress={() => navigation.replace(ScreenNames.SettingsScreen, { previousScreen: ScreenNames.MainScreen })}
      />
    </>
  );

  const body = (
    <>
      <TouchableOpacity onPress={() => onCreate()}>
        <View style={styles.bodyCard}>
          <AppIcon name="plus" size="big" />
          <Text style={h1}>{i18n.t('create').toUpperCase()}</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => navigation.navigate(ScreenNames.QRCodeScanScreen)}>
        <View style={styles.bodyCard}>
          <AppIcon name="people" size="big" />
          <Text style={h1}>{i18n.t('join').toUpperCase()}</Text>
        </View>
      </TouchableOpacity>
    </>
  );

  return <PanelScreen header={header} body={body} />;
}

const styles = StyleSheet.create({
  bodyCard: {
    backgroundColor: colors.primary.regular,
    width: '100%',
    height: 200,
    borderRadius: border.radius.big,
    borderColor: colors.primary.dark,
    borderWidth: border.width,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: margin.mid,
  },
});
