import React, { useEffect, useState } from 'react';
import { Alert, Platform, StyleSheet, Text, View } from 'react-native';
import { readFromBase64 } from '../services/password-hashing';
import { JoinRoomDto } from '../models/join-room-dto';
import { getRoom } from '../services/room-api';
import PanelScreen from '../components/PanelScreen';
import { bodyText, colors, h1, h2, margin } from '../styles';
import { AppIcon } from '../components/AppIcon';
import { QRCodeScanScreenProps, ScreenNames } from '../components/AppNavigator';
import AppButton from '../components/AppButton';
import i18n from '../utils/localization';
import useBackPressHandler from '../hooks/useBackPressHandler';
import { Toast } from 'react-native-toast-notifications';
import { Camera } from 'expo-camera';
import { BarCodeScanningResult } from 'expo-camera/build/Camera.types';
import { requestBrowserCameraPermissionAsync } from '../utils/requestBrowserCameraPermissionAsync';
import ArrowBack from '../components/ArrowBack';

function instanceOfJoinRoomDto(parsedData: any) {
  return typeof parsedData === 'object' && 'pin' in parsedData;
}

export default function QRCodeScanScreen({ navigation }: QRCodeScanScreenProps) {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [scanned, setScanned] = useState(false);

  // TODO: save result to storage to request only once
  useEffect(() => {
    (async () => {
      if (Platform.OS === 'web') {
        const { granted } = await requestBrowserCameraPermissionAsync();
        setHasPermission(granted);
      } else {
        const { granted } = await Camera.requestCameraPermissionsAsync();
        setHasPermission(granted);
      }
    })();
  }, []);

  useBackPressHandler(() => navigation.replace(ScreenNames.MainScreen));

  const handleBarCodeScanned = async ({ data }: BarCodeScanningResult) => {
    if (scanned) return;
    setScanned(true);
    try {
      const parsedData = JSON.parse(readFromBase64(data));
      console.log(parsedData);
      if (instanceOfJoinRoomDto(parsedData)) {
        const joinRoomDto: JoinRoomDto = parsedData;
        await getRoom(joinRoomDto);
        navigation.replace(ScreenNames.RoomScreen, { joinRoomDto });
        setScanned(false);
      }
    } catch (ignored) {
      Alert.alert('Invalid QR code', 'The QR code you scanned is invalid', [
        { text: 'OK', onPress: () => setScanned(false) },
      ]);
    }
  };

  if (hasPermission === null) {
    const header = (
      <>
        <View>
          <ArrowBack />
        </View>
        <View>
          <Text style={h1}>{i18n.t('join').toUpperCase()}</Text>
        </View>
        <View style={{ width: 28 }}></View>
      </>
    );

    const loadingBody = (
      <View style={styles.body}>
        <AppIcon name="qr-code-scanner" size="big" color={colors.primary.dark}></AppIcon>
        <Text style={styles.bodyText}>{i18n.t('loading')}</Text>
      </View>
    );

    return <PanelScreen body={loadingBody} header={header} />;
  }

  if (!hasPermission) {
    Toast.show(i18n.t('cameraPermissionNotGranted'), { type: 'warning' });
    console.warn('Camera permission is not granted');
    navigation.replace(ScreenNames.ManualInputConnectionScreen);
  }

  return (
    <View style={styles.body}>
      <Camera
        barCodeScannerSettings={{
          barCodeTypes: ['qr'],
        }}
        onBarCodeScanned={handleBarCodeScanned}
        style={styles.camera}
        ratio={'4:3'}
      >
        <AppButton
          onPress={() => navigation.replace(ScreenNames.ManualInputConnectionScreen)}
          buttonStyles={{ marginBottom: margin.big * 2 }}
        >
          <Text style={{ ...h2, color: colors.secondary.light, marginHorizontal: margin.big }}>
            {i18n.t('manualInput')}
          </Text>
        </AppButton>
      </Camera>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyText: {
    ...bodyText,
    color: colors.primary.dark,
    marginTop: margin.mid,
  },
  camera: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    aspectRatio: 3 / 4,
  },
});
