import { Avatar } from '@rneui/base';
import { Text } from '@rneui/themed';
import AppButton from '../../components/AppButton';
import { AppIcon } from '../../components/AppIcon';
import AppMenu from '../../components/AppMenu';
import { Player } from '../../models/room-state';
import { bodyText, border, colors, margin, shadow } from '../../styles';
import { StyleSheet, View } from 'react-native';
import GenderSelector from './GenderSelector';
import { useState } from 'react';
import i18n from '../../utils/localization';

export interface PlayerListProps {
  player: Player;
  showMoreButton: boolean;
  onLevelInc: (increment: number) => void;
  onGenderChange: (isMale: boolean) => void;
  onAddNewAdmin: () => void;
  onKickOut: () => void;
  onChangeName: () => void;
}

export function PlayerItem({
  player,
  showMoreButton,
  onLevelInc,
  onGenderChange,
  onAddNewAdmin,
  onKickOut,
  onChangeName,
}: PlayerListProps) {
  const [selectedMenuPlayerId, setSelectedMenuPlayerId] = useState<string>();

  return (
    <View style={styles.itemCard}>
      <View style={styles.leftSide}>
        <View style={styles.avatarWrapper}>
          <Avatar
            rounded
            title={player.level.toString()}
            containerStyle={{ ...styles.avatar, opacity: 0.4 + player.level * 0.06 }}
          />
          {player.isAdmin && (
            <View style={{ position: 'relative', top: -16, left: 24 }}>
              <AppIcon name="shield-crown" size="small" color={colors.warning} />
            </View>
          )}
        </View>
        <View style={styles.nameWrapper}>
          <Text style={bodyText} numberOfLines={1}>
            {player.name}
          </Text>
          <GenderSelector
            onGenderChange={(isMale: boolean) => onGenderChange(isMale)}
            playerIsMale={player.isMale}
          ></GenderSelector>
        </View>
      </View>
      <View style={styles.rightSide}>
        <AppButton onPress={() => onLevelInc(-1)} buttonStyles={styles.rightSideButton}>
          <AppIcon name="minus" size="small"></AppIcon>
        </AppButton>
        <AppButton onPress={() => onLevelInc(1)} buttonStyles={styles.rightSideButton}>
          <AppIcon name="plus" size="small"></AppIcon>
        </AppButton>
        {showMoreButton && (
          <AppMenu
            options={[
              {
                iconName: 'shield-crown',
                text: i18n.t('makeAnAdmin'),
                onPress: () => {
                  setSelectedMenuPlayerId(undefined);
                  onAddNewAdmin();
                },
                skip: player.isOffline || player.isAdmin,
              },
              {
                iconName: 'edit',
                text: i18n.t('changeName'),
                onPress: () => {
                  setSelectedMenuPlayerId(undefined);
                  onChangeName();
                },
                skip: !player.isOffline,
              },
              {
                iconName: 'cancel',
                text: i18n.t('kickOut'),
                iconColor: colors.error,
                onPress: () => {
                  setSelectedMenuPlayerId(undefined);
                  onKickOut();
                },
              },
            ]}
            visible={selectedMenuPlayerId === player.id}
            onRequestClose={() => setSelectedMenuPlayerId(undefined)}
          >
            <AppIcon
              name="more-vertical"
              size="medium"
              color={colors.primary.dark}
              onPress={() => setSelectedMenuPlayerId(player.id)}
            />
          </AppMenu>
        )}
      </View>
    </View>
  );
}

export const styles = StyleSheet.create({
  itemCard: {
    backgroundColor: colors.secondary.light,
    borderRadius: border.radius.big,
    borderColor: colors.primary.regular,
    borderWidth: 1,
    paddingHorizontal: margin.big,
    paddingVertical: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    ...shadow,
    marginBottom: margin.big,
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  avatarWrapper: {
    height: 34,
    marginLeft: margin.mid,
    marginRight: 24,
  },
  avatar: {
    backgroundColor: colors.primary.regular,
  },
  nameWrapper: {
    flex: 1,
    flexShrink: 1,
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: margin.small,
  },
  rightSideButton: {
    width: 38,
    height: 38,
    marginRight: margin.small,
  },
});
