import { Text, View } from 'react-native';
import { h1 } from '../../styles';
import i18n from '../../utils/localization';
import PanelScreen from '../../components/PanelScreen';
import { ScreenNames, SettingsScreenProps } from '../../components/AppNavigator';
import React, { useState } from 'react';
import { storage, StorageKeys } from '../../utils/storage';
import AppButton from '../../components/AppButton';
import SexSwitcher from './SexSwitcher';
import DisplayAlwaysOnSwitcher from './DisplayAlwaysOnSwitcher';
import { LocalizationPicker } from './LocalizationPicker';
import { isDevelopment } from '../../env.json';
import NameEditor from './NameEditor';
import VersionItem from './VersionItem';
import useBackPressHandler from '../../hooks/useBackPressHandler';
import ArrowBack from '../../components/ArrowBack';
import PrivacyPolicy from "./PrivacyPolicy";

export interface SettingsScreenRouteParams {
  previousScreen: ScreenNames;
  previousScreenParams?: any;
}

export default function SettingsScreen({ navigation, route }: SettingsScreenProps) {
  const { previousScreen, previousScreenParams } = route.params;

  // store locale here to force rerender on locale change
  const [locale, setLocale] = useState(storage.getString(StorageKeys.LOCALE)!);

  useBackPressHandler(() => navigation.replace(previousScreen, previousScreenParams));

  const header = (
    <>
      <View>
        <ArrowBack onPress={() => navigation.replace(previousScreen, previousScreenParams)} />
      </View>
      <View>
        <Text style={h1}>{i18n.t('settings').toUpperCase()}</Text>
      </View>
      <View style={{ width: 28 }} />
    </>
  );

  const body = (
    <View>
      {isDevelopment && (
        <AppButton onPress={() => storage.clearAll()} type={'danger'}>
          Clear storage
        </AppButton>
      )}
      <NameEditor />
      <SexSwitcher />
      <DisplayAlwaysOnSwitcher />
      <LocalizationPicker locale={locale} setLocale={setLocale} />
      <VersionItem />
      <PrivacyPolicy />
    </View>
  );

  return <PanelScreen header={header} body={body} />;
}
