import env from '../env.json';
import { RoomCreationOptions, RoomDto } from '../../commons';
import { convertToBase64 } from './password-hashing';
import { JoinRoomDto } from '../models/join-room-dto';

const baseRoomUrl = `${env.url}/api/v1/rooms`;

export async function createNewOnlineRoom(adminName: string, state: object): Promise<RoomDto> {
  const body: RoomCreationOptions = {
    adminName: adminName,
    initialState: state,
  };
  return fetch(baseRoomUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json());
}

export async function getRoom(joinRoomDto: JoinRoomDto): Promise<RoomDto> {
  const url = `${baseRoomUrl}?pin=${joinRoomDto.pin}`;
  return fetch(url, {
    method: 'GET',
  }).then((response) => response.json());
}
