import { AppIcon } from '../../components/AppIcon';
import { colors } from '../../styles';

export interface GenderSelectorProps {
  playerIsMale: boolean;
  onGenderChange: (newGenderIsMale: boolean) => void;
}

export default function GenderSelector(props: GenderSelectorProps) {
  return (
    <AppIcon
      name={props.playerIsMale ? 'male-symbol' : 'female-symbol'}
      color={props.playerIsMale ? colors.gender.male : colors.gender.female}
      size="small"
      onPress={() => props.onGenderChange(!props.playerIsMale)}
    />
  );
}
