export function validate<T>(
  value: T,
  validateFunc: (val: T) => string | undefined,
  setErrorFunc: (val: string | undefined) => void
): boolean {
  const error = validateFunc(value);
  const isValid = !error;
  setErrorFunc(error);
  return isValid;
}

export function getPinValidationError(newValue: string): string | undefined {
  let error;
  if (!newValue) {
    error = 'Pin is required';
  } else if (newValue.length != 6) {
    error = 'Pin must be 6 symbols';
  }
  return error;
}

export function getNameValidationError(newValue: string | undefined): string | undefined {
  let error;
  if (!newValue) {
    error = 'Name is required';
  }
  return error;
}
