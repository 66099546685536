import {
  AntDesign,
  Entypo,
  Feather,
  FontAwesome,
  FontAwesome5,
  Foundation,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import { colors } from '../styles';
import { ComponentType } from 'react';
import { IconButtonProps } from '@expo/vector-icons/build/createIconSet';

type Size = 'small' | 'big' | 'medium';

export type IconName =
  | 'infocirlceo'
  | 'edit'
  | 'plus'
  | 'minus'
  | 'male-symbol'
  | 'female-symbol'
  | 'more-horizontal'
  | 'arrow-back'
  | 'qr-code-scanner'
  | 'settings'
  | 'drag-indicator'
  | 'admin-panel-settings'
  | 'cancel'
  | 'sad-cry'
  | 'shield-crown'
  | 'more-vertical'
  | 'people'
  | 'warning'
  | 'check-circle'
  | 'close-circle-outline'
  | 'info-with-circle'
  | 'copy-outline'
  | 'create-outline';

export interface AppIconProps {
  name: IconName;
  color?: string;
  size: Size;
  onPress?: () => void;
}

const sizes: Map<Size, number> = new Map([
  ['small', 24],
  ['medium', 28],
  ['big', 48],
]);

const getStyle = (size: Size): { width: number; height: number } => {
  const value = sizes.get(size);
  if (!value) {
    throw new Error(`AppIcon: no such 'size' property as ${size}`);
  }
  return { width: value, height: value };
};

const iconComponents: Map<IconName, ComponentType<IconButtonProps<IconName>>> = new Map([
  ['minus', AntDesign],
  ['plus', AntDesign],
  ['infocirlceo', AntDesign],

  ['male-symbol', Foundation],
  ['female-symbol', Foundation],

  ['arrow-back', Ionicons],
  ['copy-outline', Ionicons],
  ['create-outline', Ionicons],

  ['more-horizontal', Feather],
  ['more-vertical', Feather],
  ['settings', Feather],
  ['edit', Feather],

  ['qr-code-scanner', MaterialIcons],
  ['drag-indicator', MaterialIcons],
  ['cancel', MaterialIcons],
  ['admin-panel-settings', MaterialIcons],
  ['people', MaterialIcons],

  ['warning', FontAwesome],
  ['check-circle', FontAwesome],

  ['sad-cry', FontAwesome5],

  ['shield-crown', MaterialCommunityIcons],
  ['close-circle-outline', MaterialCommunityIcons],

  ['info-with-circle', Entypo],
]);

export function AppIcon({ name, color, size, onPress }: AppIconProps) {
  const style = getStyle(size);
  const IconComponent = iconComponents.get(name);

  if (!IconComponent) {
    throw new Error(`AppIcon: no such 'name' property as ${name}`);
  }

  return (
    <IconComponent name={name} style={style} size={style.width} color={color ?? colors.text.onDark} onPress={onPress} />
  );
}
