import AppOverlay from '../../components/AppOverlay';
import React from 'react';
import i18n from '../../utils/localization';

export interface GoBackConfirmationOverlayProps {
  isOpened: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export default function GoBackConfirmationOverlay({ isOpened, onCancel, onConfirm }: GoBackConfirmationOverlayProps) {
  return (
    <AppOverlay
      visible={isOpened}
      headerText={i18n.t('leaveConfirmation')}
      onClose={() => onCancel()}
      onCloseLabel={i18n.t('cancel')}
      okButtonShow={true}
      okButtonLabel={i18n.t('confirm')}
      onOk={() => onConfirm()}
    />
  );
}
