import { CameraType, PermissionResponse } from 'expo-camera/src/Camera.types';
import { getPreferredStreamDevice } from 'expo-camera/src/WebCameraUtils';
import { Camera } from 'expo-camera';

export async function requestBrowserCameraPermissionAsync(): Promise<PermissionResponse> {
  try {
    await getPreferredStreamDevice(CameraType.back).then((mediaStream) =>
      // @ts-ignore
      mediaStream.getTracks().forEach((track) => track.stop())
    );
  } catch (ignored) {}

  return await Camera.getCameraPermissionsAsync();
}
