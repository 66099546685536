import { Player, RoomState } from '../models/room-state';
import jsonPatch, { Operation } from 'fast-json-patch';
import { deepCopy } from '../utils/deep-copy';
import { storage, StorageKeys } from '../utils/storage';
import { v4 as uuid } from 'uuid';

export function getUpdatePlayerPatch(original: RoomState, playerId: string, newName: string): Operation[] {
  return updatePlayer(original, playerId, (player) => (player.name = newName));
}

export function getAddMeAsPlayerPatch(original: RoomState): Operation[] {
  return getAddNewPlayerPatch(
    original,
    storage.getString(StorageKeys.PLAYER_ID)!,
    storage.getString(StorageKeys.USER_NAME)!,
    storage.getBoolean(StorageKeys.PLAYER_GENDER_IS_MALE)!,
    false
  );
}

export function getAddOfflinePlayerPatch(original: RoomState, name: string): Operation[] {
  return getAddNewPlayerPatch(original, uuid(), name, true, true);
}

function getAddNewPlayerPatch(
  original: RoomState,
  id: string,
  name: string,
  isMale: boolean,
  isOffline: boolean
): Operation[] {
  const copy = deepCopy(original);
  const observer = jsonPatch.observe<RoomState>(copy);
  copy.players.push({
    id: id,
    name: name,
    level: 1,
    isMale: isMale,
    isAdmin: false,
    isOffline,
  });
  return jsonPatch.generate(observer);
}

export function getKickPlayerPatch(original: RoomState, playerId: string): Operation[] {
  const copy = deepCopy(original);
  const observer = jsonPatch.observe<RoomState>(copy);
  copy.players = copy.players.filter((player) => player.id !== playerId);
  return jsonPatch.generate(observer);
}

export function getChangePlayerLevelPatch(original: RoomState, playerId: string, inc: number): Operation[] {
  return updatePlayer(original, playerId, (player) => (player.level += inc));
}

export function getChangeGenderPatch(original: RoomState, playerId: string, newGenderIsMale: boolean): Operation[] {
  return updatePlayer(original, playerId, (player) => (player.isMale = newGenderIsMale));
}

export function getChangeAdminRightsPatch(original: RoomState, playerId: string): Operation[] {
  return updatePlayer(original, playerId, (player) => (player.isAdmin = true));
}

export function getChangePlayerNamePatch(original: RoomState, playerId: string, newName: string): Operation[] {
  return updatePlayer(original, playerId, (player) => (player.name = newName));
}

const updatePlayer = (original: RoomState, playerId: string, callback: (p: Player) => void) => {
  const originalCopy: RoomState = deepCopy(original);
  const observer = jsonPatch.observe<RoomState>(originalCopy);
  const player = originalCopy.players.find((player) => player.id === playerId);
  if (!player) throw new Error('No such player');
  callback(player);
  return jsonPatch.generate(observer);
};
